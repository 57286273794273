import React, { useState } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

import { Player } from 'video-react';
import "../../../node_modules/video-react/dist/video-react.css"; // import css

import { FormGroup, Input, Label, CustomInput } from "reactstrap";
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container, 
  Row,
  Col
} from "reactstrap";

export default function VideoUploader(props) {
    const index = props.index;
    const name = "media"+index;
    const isPreview = props.isPreview;

    const [form, setForm] = [props.form, props.setForm];
    const [file, setFile] = useState();

    const fileHandler = (event) => {
      setFile(event.target.files[0]);
      setForm({
        ...form,
        [name]: {
          type: 1,
          file: event.target.files[0],
          preview: URL.createObjectURL(event.target.files[0])
        }
      });
    };
   
    return (
      <Container style={{padding:0}}>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} style={{marginTop:"16px", display: (isPreview ? "none" : "block") }}>
            <FormGroup>
              <Label for={name+"_video"}>Upload Video</Label>
              <CustomInput onChange={fileHandler} accept="Video/*" type="file" id={name+"_video"} name={name} className="form-Control"/>
            </FormGroup>
          </Col>
          <Col s={12} sm={12} md={12} lg={12} style={{display: (isPreview ? "none" : "block")}}>
            <FormGroup>
              <Label for="">Video Specification</Label>
              <Container>
                <Row>
                  <Col s={12} sm={12} md={6} lg={6}><li>Size limit: 25MB</li></Col>
                  <Col s={12} sm={12} md={6} lg={6}><li>Duration: Unlimited</li></Col>
                  <Col s={12} sm={12} md={6} lg={6}><li>Max Resolution: 1920x1080</li></Col>
                  <Col s={12} sm={12} md={6} lg={6}><li>Supported file format: .mp4</li></Col>
                </Row>
              </Container>
            </FormGroup>
          </Col>
        </Row>

        <Row >
          <Col xs={12}>
            <Player
              playsInline
              src={form[name]? form[name].preview : null}
            />
          </Col>
        </Row>
      </Container>
    );
  }
  