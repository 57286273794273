import React, { useState } from "react";
import { Button, Input, Container, Col, Row, CustomInput, FormGroup, Label } from "reactstrap";

import MediaUploadArea from "components/MediaUploader/MediaUploadArea";
import "assets/css/UploadingArea.css";

import { Player } from 'video-react';
import "../../../../node_modules/video-react/dist/video-react.css"; // import css

export default function UploadSubmit(props) {
  const room = props.room;
  const [form, setForm] = [props.form, props.setForm];

  return (
    <Container className="Uploading-Area">
      <Row>
        <Col className="mb-2" xs="12">
          <div>
            <i style={{marginTop: "-2px", marginRight: "8px"}} className={"fas fa-question-circle"} />
            Please confirm your material
          </div>
        </Col>

        <Col xs={12} md={8} lg={8}>
          <FormGroup>
            <Label for="companyName">Company Name</Label>
            <Input
              // type="email"
              id="companyName"
              name="companyName"
              // placeholder={}
              value={form.companyName}
              // disabled
            />
          </FormGroup>
        </Col>

        <Col xs={12} md={4} lg={4}>
          <FormGroup>
            <Label for="logo">Logo</Label>
            <br/>
            <img style={{width:"10rem", height: "auto"}} src={form.logoPreview} />
          </FormGroup>
        </Col>

        <Col xs={12}>
          <FormGroup>
            <Label for="companyWebsite">Official Website</Label>
            <Input
              // type="email"
              id="companyWebsite"
              name="companyWebsite"
              value={form.companyWebsite}
            />
          </FormGroup>
        </Col>

        <Col xs={12}>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input 
              type="textarea" 
              name="description" 
              id="description" 
              value={form.description}
              rows={6}
            />
          </FormGroup>
        </Col>

        <Col xs={12} sm={12} md={12} lg={12}>
          <FormGroup>
            <Label for="main_video_upload">Promotion Video</Label>
            <Player
              playsInline
              src={form.videoPreview}
            />
          </FormGroup>
          <h3></h3>
        </Col>

        <Col xs={12} sm={12} md={12} lg={12}>
          <FormGroup>
            <Label for="main_video_upload">Media Upload</Label>
            <MediaUploadArea room={room} form={form} setForm={setForm} isPreview/>
          </FormGroup>
        </Col>
      </Row>  

      <Row>
        <Col xs={12}>
          <Button className="btn-round w-100 mt-3" color="primary">
            <i className="tim-icons icon-upload" /> Submit 
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
