/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";

import "assets/css/welcomingPage.css";
import Room_A from "assets/img/room_preview/RoomA.png";
import Room_B from "assets/img/room_preview/RoomB.png";
import Room_C from "assets/img/room_preview/RoomC.png";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import SiteNavBar from "components/Navbars/SiteNavBar.js";
import Footer from "components/Footer/Footer.js";
import RoomSelector from "components/RoomSelector";

export default function RegisterPage() {
  const [squares1to6, setSquares1to6] = React.useState("");
  const [squares7and8, setSquares7and8] = React.useState("");

  React.useEffect(() => {
    document.body.classList.toggle("register-page");
    document.documentElement.addEventListener("mousemove", followCursor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("register-page");
      document.documentElement.removeEventListener("mousemove", followCursor);
    };
  },[]);

  const followCursor = (event) => {
    let posX = event.clientX - window.innerWidth / 2;
    let posY = event.clientY - window.innerWidth / 6;
    setSquares1to6(
      "perspective(500px) rotateY(" +
        posX * 0.05 +
        "deg) rotateX(" +
        posY * -0.05 +
        "deg)"
    );
    setSquares7and8(
      "perspective(500px) rotateY(" +
        posX * 0.02 +
        "deg) rotateX(" +
        posY * -0.02 +
        "deg)"
    );
  };

  const roomInfo = [
    {
      name: "Studio",
      img_src: Room_A,
      desc: `Studio with office set up equips with Video screening, images display, models display.\n\nSuitable for new IP/artist/start up.`
    },
    {
      name: "Gallery",
      img_src: Room_B,
      desc: `Gallery with 3 separate zones. 3 zones separate as images display, cinema room and animated/assets showroom areas.\n\nSuitable for IPs/artists/companies with lots of showcases and assets to display.`
    },
    {
      name: "Room 3",
      img_src: Room_C,
      desc: `Coming Soon...`
    },
  ]

  return (
    <>
      <SiteNavBar />
      <div className="wrapper">
        <div className="page-header">
          <div className="page-header-image" />
          <div className="content">
            <Container>
            <Row>
                <Col className="fullpage" lg="12" md="12">
                  <div className="fullpage-content mt-5">
                    <h1 className="landing-main">
                    CaVA  
                    </h1>
                    <h3 className="landing-sub">
                      CaVA is a virutal platform / community for 
                      <br/>digital entertinament for Digital and Arts.
                      <br/>CaVA is a community to exhibit, to connect and live streaming/events. 
                      <br/>Participants will exprience a game-like virtual experiences to immersive in CaVA, tailored and art style environments. 
                    </h3>
                  </div>
                </Col>
              </Row>
              <Row>
                {
                  roomInfo.map((value, index) => {
                    return (
                      <Col className="" lg="4" md="12">
                        <div
                          className="square square-7"
                          id="square7"
                          style={{ transform: squares7and8 }}
                        />
                        <RoomSelector 
                          img_src={value.img_src}
                          roomIndex={index}
                          title={value.name}
                          desc={value.desc}
                        />
                      </Col>
                    )
                  })
                }
              </Row>
              {/* <div className="register-bg" /> */}
              <div
                className="square square-1"
                id="square1"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-2"
                id="square2"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-3"
                id="square3"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-4"
                id="square4"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-5"
                id="square5"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-6"
                id="square6"
                style={{ transform: squares1to6 }}
              />
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
