import React, { useState } from "react";
import classnames from "classnames";

import Room_A from "assets/img/room_preview/RoomA.png";
import ImageUploader from "./ImageUploader";
import VideoUploader from "./VideoUploader";
import TextUploader from "./TextUploader";

import { FormGroup, Input, Label } from "reactstrap";
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container, 
  Row,
  Col
} from "reactstrap";


export default function MediaUploadArea(props) {
    const room = props.room;
    const availableFrameSize = [6,6,6];

    const isPreview = props.isPreview;
    const [form, setForm] = [props.form, props.setForm];
    const [withIcons, setWithIcons] = useState(0);

    const handleChange = (event) => {
      const name = event.target.name;
      setForm({
        ...form,
        [name]: {
          type: event.target.value
        },
      });
    };

    console.log(room);

    return (
      <Container style={{padding: "inherit"}}>
        <Row>
          <Col xs={12} md={2}>
            <Nav 
              className="nav-pills-primary nav-pills-icons"
              pills
              role="tablist"
            >
              {
                [...Array(availableFrameSize[room]).keys()].map((data, index) => {
                  const value = form['media'+index] ? form['media'+index].type : 0;

                  return (
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: withIcons === index,
                          frameButton: true
                        })}
                        onClick={e => setWithIcons(index)}
                        href="#"
                      >
                        <i 
                          className={classnames({
                            ["icon-image-02"]: value == 0,
                            ["icon-video-66"]: value == 1,
                            ["icon-paper"]: value == 2,
                            ["icon-simple-remove"]: value == 999,
                            ["tim-icons"]: true
                          })}
                        />
                        Frame {index+1}
                      </NavLink>
                    </NavItem>
                  )
                })
              }
            </Nav>
          </Col>
          <Col xs={12} md={10}>
            <TabContent className="tab-space" activeTab={"withIcons" + withIcons}>
            {
                [...Array(availableFrameSize[room]).keys()].map((data, index) => {
                  const value = form['media'+index] ? form['media'+index].type : 0;
                  const uploader = [
                    <ImageUploader index={index} form={form} setForm={setForm} isPreview={isPreview} />, 
                    <VideoUploader index={index} form={form} setForm={setForm} isPreview={isPreview} />, 
                    <TextUploader index={index} form={form} setForm={setForm} isPreview={isPreview} />
                  ]

                  return (
                    <TabPane tabId={"withIcons"+index}>
                      <Container>
                        <Row>
                          <Col xs={12} md={4}>
                            <img
                              style={{height: "auto", width: "100%", display: "block"}}
                              src={Room_A}
                            />
                          </Col>
                          <Col xs={12} md={8}>
                            <FormGroup>
                              <Label for={"Input_Type_"+index}>Media Type</Label>
                              <Input 
                                disabled={isPreview}
                                type="select" 
                                name={'media'+index}
                                id={"Input_Type_"+index}
                                value={value}
                                onChange={handleChange}
                                className="media-select"
                              >
                                <option value={0}>Image</option>
                                <option value={1}>Video</option>
                                <option value={2}>Text</option>
                                <option value={999}>Disable this frame</option>
                              </Input>
                            </FormGroup>

                            { uploader[value] }
                          </Col>
                        </Row>
                      </Container>
                    </TabPane>
                  )
                })
              }
            </TabContent>
          </Col>
        </Row>
      </Container>
    );
  }
  