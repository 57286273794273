import React, { useState } from "react";
import { Redirect } from "react-router";

import classnames from "classnames";
import "assets/css/uploadingPage.css";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane
} from "reactstrap";

import SiteNavBar from "components/Navbars/SiteNavBar.js";
import Footer from "components/Footer/Footer.js";

import UploadBasicInfo from "./Sections/UploadBasicInfo.js";
import UploadMedia from "./Sections/UploadMedia.js";
import UploadSubmit from "./Sections/UploadSubmit.js";

export default function UploadingPage(props) {
  React.useEffect(() => {
    document.body.classList.toggle("index-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("index-page");
    };
  },[]);

  const formBasic = {
    name: "",
    logo: { name: "" },
    description: "" ,
    frames: {}
  }
  const [form, setForm] = useState({...formBasic});
  const [withIcons, setWithIcons] = useState(1);

  var room = 0;
  if(!props.location.state || (props.location.state.room == undefined)){
    return <Redirect to="/" />
  } else {
    room = props.location.state.room;
  }

  return (
    <>
      <SiteNavBar />
      <div className="wrapper">
        {/* <PageHeader /> */}
        <div className="main container">
          <Nav
            className="nav-pills-primary nav-pills-icons"
            pills
            role="tablist"
          >
            {/* color-classes: "nav-pills-primary", "nav-pills-info", "nav-pills-success", "nav-pills-warning","nav-pills-danger" */}
            <NavItem>
              <NavLink
                className={classnames({
                  active: withIcons === 1
                })}
                onClick={e => setWithIcons(1)}
                href="#"
              >
                <i className="tim-icons icon-pencil" />
                Information
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: withIcons === 2
                })}
                onClick={e => setWithIcons(2)}
                href="#"
              >
                <i className="tim-icons icon-palette" />
                Media
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: withIcons === 3
                })}
                onClick={e => setWithIcons(3)}
                href="#"
              >
                <i className="tim-icons icon-cloud-upload-94" />
                Confirm
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent className="tab-space" activeTab={"withIcons" + withIcons}>
            <TabPane tabId="withIcons1">
              <UploadBasicInfo room={room} form={form} setForm={setForm} />
            </TabPane>
            <TabPane tabId="withIcons2">
              <UploadMedia room={room} form={form} setForm={setForm} />
            </TabPane>
            <TabPane tabId="withIcons3">
              <UploadSubmit room={room} form={form} setForm={setForm} />
            </TabPane>
          </TabContent>
        </div>
        <Footer />
      </div>
    </>
  )
}
  // return (
  //   // <NavPills
  //   //   animateHeight
  //   //   color="primary"
  //   //   tabs={[
  //   //     {
  //   //       tabButton: "Basic Info",
  //   //       tabIcon: InfoIcon,
  //   //       tabContent: (
  //   //         <UploadBasicInfo form={form} setForm={setForm}/>
  //   //       )
  //   //     },
  //   //     {
  //   //       tabButton: "Media",
  //   //       tabIcon: PermMediaIcon,
  //   //       tabContent: (
  //   //         <UploadMedia form={form} setForm={setForm} room={room}/>
  //   //       )
  //   //     },
  //   //     {
  //   //       tabButton: "Submit",
  //   //       tabIcon: PublishIcon,
  //   //       tabContent: (
  //   //         <UploadSubmit form={form} setForm={setForm} room={room}/>
  //   //       )
  //   //     }
  //   //   ]}
  //   // />
  // );
// }
