import React, { useState } from "react";
import { Container, Col, Row, CustomInput, FormGroup, Label } from "reactstrap";
import "assets/css/UploadingArea.css";

import MediaUploadArea from "components/MediaUploader/MediaUploadArea";


export default function UploadMedia(props) {
  const room = props.room;
  const [form, setForm] = [props.form, props.setForm];
  const [video, setVideo] = useState();

  const fileHandler = (event) => {
		setVideo(event.target.files[0]);
    setForm({
      ...form,
      "video": event.target.files[0],
      "videoPreview": URL.createObjectURL(event.target.files[0])
    });
	};

  return (
    <Container className="Uploading-Area">
      <Row>
        <Col className="mb-2" xs="12">
          <div>
            <i style={{marginTop: "-2px", marginRight: "8px"}} className={"fas fa-question-circle"} />
            Upload your promotion media here
          </div>
        </Col>

        <Col xs={12} sm={12} md={4} lg={4}>
          <FormGroup>
            <Label for="main_video_upload">Promotion Video</Label>
            <CustomInput onChange={fileHandler} accept="video/*" type="file" id="main_video_upload" name="mainVideo" className="form-Control"/>
          </FormGroup>
          <h3></h3>
        </Col>
        <Col xs={12} sm={12} md={8} lg={8}>
          <FormGroup>
            <Label for="main_video_upload">Video Specification</Label>
            <Container>
              <Row>
                <Col s={12} sm={12} md={6} lg={6}><li>Size limit: 25MB</li></Col>
                <Col s={12} sm={12} md={6} lg={6}><li>Duration: Unlimited</li></Col>
                <Col s={12} sm={12} md={6} lg={6}><li>Max Resolution: 1920x1080</li></Col>
                <Col s={12} sm={12} md={6} lg={6}><li>Supported file format: .mp4</li></Col>
              </Row>
            </Container>
          </FormGroup>
        </Col>

        <Col xs={12} sm={12} md={12} lg={12}>
          <FormGroup>
            <Label for="main_video_upload">Media Upload</Label>
            <MediaUploadArea room={room} form={form} setForm={setForm} />
          </FormGroup>
        </Col>
      </Row>  
    </Container>
  );
}
