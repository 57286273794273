import React, { useState } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

import { Player } from 'video-react';
import "../../../node_modules/video-react/dist/video-react.css"; // import css

import { FormGroup, Input, Label, CustomInput } from "reactstrap";
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container, 
  Row,
  Col
} from "reactstrap";

export default function TextUploader(props) {
    const index = props.index;
    const name = "media"+index;
    const isPreview = props.isPreview;

    const [form, setForm] = [props.form, props.setForm];

    const fileHandler = (event) => {
      setForm({
        ...form,
        [name]: {
          type: 2,
          file: event.target.value
        }
      });
    };
   
    return (
      <Container style={{padding:0}}>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} style={{marginTop:"16px"}}>
            <FormGroup >
              <Label for="description">Text Content</Label>
              <Input 
                disabled={isPreview}
                type="textarea" 
                name={name} 
                id={name+"_text"} 
                onChange={fileHandler}
                rows={12}
                placeholder={isPreview ? form[name].file : "Information about your company..."}
              />
            </FormGroup>
          </Col>
        </Row>
      </Container>
    );
  }
  