import React from "react";
import classnames from "classnames";
import { useHistory } from 'react-router-dom';

import "assets/css/RoomSelector.css";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardImg,
    CardTitle,
  } from "reactstrap";

export default function RoomSelector(props) {
    const history = useHistory();
    console.log(props.desc);
    console.log(props.title);

    var RoomselectHandler = ()=>{
        history.push("/upload", {room: props.roomIndex});
    }

    return (
        <Card className="card-register" id={"room"+props.roomIndex}>
            <CardHeader>
                <CardImg
                alt="..."
                src={props.img_src}
                />
                <CardTitle tag="h4">{props.title}</CardTitle>
            </CardHeader>
            <CardBody>
                <h4 className="desc">{props.desc}</h4>
            </CardBody>
            <CardFooter>
                <Button className="btn-round btn-preview" color="info" block>
                    <i style={{marginTop: "-2px"}} className={"fab fa-youtube"} />
                    <h4>Preview</h4>
                </Button>
                <Button onClick={RoomselectHandler} className="btn-round btn-submit" color="success" block>
                    <i style={{marginTop: "-2px"}} className={"fas fa-check"} />
                    <h4>Choose this one!</h4>
                </Button>
            </CardFooter>
        </Card>
    );
  }
  