import React, { useState } from "react";
import {   
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Card,
  CardBodyCol, Container, Col, Row, CustomInput} from "reactstrap";

export default function UploadBasicInfo(props) {
  const [selectedLogo, setSelectedLogo] = useState();

  const handleChange = (event) => {
    const name = event.target.name;
    props.setForm({
      ...props.form,
      [name]: event.target.value,
    });
  };
  const fileHandler = (event) => {
		setSelectedLogo(event.target.files[0]);
    props.setForm({
      ...props.form,
      "logo": event.target.files[0],
      "logoPreview": URL.createObjectURL(event.target.files[0])
    });
	};

  return (
    <Container>
      <Row>
        <Col className="mb-2" xs="12">
          <div className="">
            <i style={{marginTop: "-2px", marginRight: "8px"}} className={"fas fa-question-circle"} />
            Tell us about your company
          </div>
        </Col>

        <Col xs={12} md={8} lg={8}>
          <FormGroup>
            <Label for="companyName">Company Name</Label>
            <Input
              // type="email"
              id="companyName"
              name="companyName"
              placeholder="XYZ Company"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>

        <Col xs={12} md={4} lg={4}>
          <FormGroup>
            <Label for="logo">Logo</Label>
            <CustomInput onChange={fileHandler} accept="image/*" type="file" id="logo" name="logo" className="form-Control"/>
          </FormGroup>
        </Col>

        <Col xs={12}>
          <FormGroup>
            <Label for="companyWebsite">Official Website</Label>
            <Input
              // type="email"
              id="companyWebsite"
              name="companyWebsite"
              placeholder="https://www.website.com"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>

        <Col xs={12}>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input 
              type="textarea" 
              name="description" 
              id="description" 
              onChange={handleChange}
              rows={6}
              bsSize="lg"
              placeholder="Something about your company..."
            />
          </FormGroup>
        </Col>
      </Row>
    </Container>
  );
}
